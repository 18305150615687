<template>
  <div class="content">
    <div class="titleWrap">
      <!--            <van-icon name="arrow-left" style="line-height: 42px"/>-->
      <div class="titleText">
        <p>{{ startAddress }}</p>
        <img
          :src="tripType === 'OW' ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/15/yuelvhuiwYaqChQanm1609141435.png'"
          alt=""
        >
        <p>{{ endAddress }}</p>
      </div>
    </div>
    <div class="tickedTimeDetail">
      <div class="AviationInfo">
        <p><span>{{ TravelText }}</span></p>
        <p>{{ ticketName }}{{ ticketCode }}</p>
        <p>{{ departureDate }}</p>
        <p>{{ departureWeek }}</p>
      </div>
      <div class="TimeInfo">
        <div class="stareTimeInfo">
          <p>{{ departureStartTime }}</p>
          <p>{{ departureAirport }}</p>
        </div>
        <div class="TimeImg">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
            alt=""
          >
        </div>
        <div class="endTimeInfo">
          <p>{{ departureEndTime }}</p>
          <p>{{ landingAirport }}</p>
        </div>
      </div>
    </div>
    <div class="van-hairline--bottom" />
    <div class="priceTipsInfo">
      <div class="priceTipsInfoContent">
        <p>成人票：¥{{ adultPrice }}+基建燃油费¥{{ adultFuelCosts }}+{{ tax }};</p>
        <p style="margin-left: -2px;">儿童票：¥{{ childrenPrice }}+基建燃油费¥0;</p>
        <div class="tickedListLeftContent" style="margin-left: -10px;" @click="showNote">
          <p>
            <span>{{ baseCabinName }}{{ discount }}折 | 退改¥{{ refundExp }}起 | </span>
            <span v-if="weight === 0">无免费托运行李额</span>
            <span v-else>托运行李{{ weight }}KG</span>
          </p>
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png"
            alt=""
          >
        </div>
        <p>因为机票信息随时变化，以及网络传输的问题，价格可能存在误差，请以最终支付金额为准。</p>
      </div>
    </div>
    <div class="van-hairline--bottom" />
    <div style="padding-bottom: 75px;">
      <!--        乘机人-->
      <div class="TakeOppListWrap">
        <p v-if="isPay" class="TakeOppListTitle">请选择乘机人</p>
        <div v-if="isPay" class="TakeOppList">
          <div v-if="TakeOppList.length > 0" class="TakeOppListInfos">
            <div
              v-for="(item,index) in TakeOppList"
              :key="index"
              class="TakeOppListInfo"
              :class="{'TakeOppListInfo1' : index%3===1, 'TakeOppListInfo2': item.isSel===true,'TakeOppListInfo3':item.isSel===false}"
              :style="item.id === 9999 ? {border:'1px solid #E33F44',color: '#E33F44'} : {}"
              @click="selOpp(item)"
            >
              {{ item.name }}
            </div>
            <!--                        选中样式-->
          </div>
          <div v-else>
            <p class="NewDataOpp" @click="IncreaseOpp">新增</p>
          </div>
        </div>
        <p v-if="isPay" class="TakeOppTips">儿童/婴儿须由成人陪同乘机</p>
        <!--                选中乘机人-->
        <div class="selOpportunity">
          <div v-for="(item,index) in selTakeOppList" :key="index" class="selOpportunityList">
            <p v-if="isPay" class="Forks" @click="clearUserInfo(item)">×</p>
            <div class="userInfo">
              <p>{{ item.name }}</p>
              <p>{{ item.idTypeText }} {{ item.idCard }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--            联系人-->
      <div v-if="isPay" class="selTakeOppListWrap">
        <p>联系人</p>
        <div class="infoContent">
          <div class="infoFill">
            <p class="infoFillTitle">姓名</p>
            <input v-model="form.name" type="text" class="infoFillInp" placeholder="请输入乘机人姓名">
          </div>
          <div class="van-hairline--bottom" />
          <div class="infoFill">
            <p class="infoFillTitle">手机号码</p>
            <input v-model="form.mobile" type="text" class="infoFillInp" placeholder="请输入手机号码">
          </div>
        </div>
      </div>
      <!--            报销凭证提示-->
      <div class="voucherWrap">
        <p>报销凭证：</p>
        <p>可在机场开具行程单。<br>如需邮寄行程单，需要额外支付快递费</p>
      </div>
    </div>
    <!--        底部支付-->
    <div class="btnPay">
      <div class="priceWrap">
        <p>在线支付 <span>¥{{ payPrice }}</span></p>
        <p>共{{ selTakeOppList.length }}人（{{ baseCabinName }}）</p>
      </div>
      <div class="payWrap">
        <p v-if="isShowDetailsText" class="DetailsText" @click="lookDetail">明细</p>
        <div class="goPay" :class="isGoPay ? '' :'ProhibitionGoPay'" @click="getOder">
          <p>去支付</p>
          <!--                    <van-count-down v-if="isPay" :time="PayTime" />-->
          <!--                    <p v-if="isPay" class="payTime">{{PayTime}}</p>-->
        </div>
      </div>
    </div>

    <!--        展示说明-->
    <van-action-sheet v-model="showDisplayActive">
      <van-tabs v-model="DisplayActive" scrollspy sticky class="DisplayActiveWrap">
        <van-tab title="票价明细" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuix6FjAWywIu1609225209.png"
              alt=""
            >
            <p>票价明细</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ adtPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ chdPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
          </div>
          <div class="TicketRemarks">
            <p>【儿童票】年龄在2周岁（含）-12周岁（不含）的常客，可预订
              儿童票</p>
          </div>
        </van-tab>
        <van-tab title="行李规定" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhui5fqqBtMpBX1609225230.png"
              alt=""
            >
            <p>免费行李规定</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}KG</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="chdBaggage !== undefined" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="退改签说明" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>退订收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>同舱改期收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>签转他航条件</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p style="width: 100%">{{ isChange }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </van-action-sheet>
    <!--        明细-->
    <van-action-sheet v-model="showDetails" title="购买明细">
      <div class="infoDetailWrap">
        <div v-if="isShowAdultInfo" class="AdultInfoDetail">
          <div class="AdultInfoPrice">
            <p>成人</p>
            <div style="width: 240px;height: 1px;background-color:#e8e8e8;margin-left: 20px;margin-top: 7px;" />
            <p>¥{{ adultPrice }}x{{ adultNum }}人</p>
          </div>
          <div class="AdultInfoTicketPrice">
            <p>机票</p>
            <p>¥{{ adultTotalPrice }}</p>
          </div>
          <div class="AdultInfoFuelCostsPrice">
            <p>基建</p>
            <p>¥{{ adultTotalFuelCosts }}</p>
          </div>
          <div class="AdultInfoTaxPrice">
            <p>燃油</p>
            <p>¥{{ adultTotalTax }}</p>
          </div>
        </div>
        <div v-if="isShowChildrenInfo" class="ChildrenInfoDetail">
          <div class="AdultInfoPrice">
            <p>儿童</p>
            <div style="width: 240px;height: 1px;background-color:#e8e8e8;margin-left: 20px;margin-top: 7px;" />
            <p>¥{{ childrenPrice }}x{{ ChildrenNum }}人</p>
          </div>
          <div class="AdultInfoTicketPrice">
            <p>机票</p>
            <p>¥{{ ChildrenTotalPrice }}</p>
          </div>
          <div class="AdultInfoFuelCostsPrice">
            <p>基建</p>
            <p>¥{{ ChildrenTotalFuelCosts }}</p>
          </div>
          <div class="AdultInfoTaxPrice">
            <p>燃油</p>
            <p>¥{{ ChildrenTotalTax }}</p>
          </div>
        </div>
        <div class="payTips">
          <p>因为机票信息随时变化，以及网络传输的问题，价格可能存在误差，请以最终支付金额为准</p>
        </div>
      </div>
    </van-action-sheet>
    <!--        弹出层-->
    <van-popup v-model="isShowPopUp" round>{{ PopUpText }}</van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet,
  CountDown,
  List,
  Loading,
  Popup,
  Sidebar,
  SidebarItem,
  Tab,
  Tabs,
  Toast,
  TreeSelect
} from 'vant'

Vue.use(Tab)
  .use(Tabs)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)
  .use(List)
  .use(Popup)
  .use(CountDown)

import {
  getProductDes,
  getTakeOppList,
  getTicketOder,
  payTicketOder
} from '@/services/comeOn.js'
import { weChatPay } from '@/utils/payment'

export default {
  name: 'ReservationTicket',
  data() {
    return {
      query: {},
      tripType: '', // 行程状态
      ticketName: '', // 航司名称
      ticketCode: '', // 航司码
      departureDate: '', // 起飞日期
      departureWeek: '', // 起飞周天
      departureStartTime: '', // 起飞开始时间
      departureEndTime: '', // 降落时间
      departureAirport: '', // 起飞机场
      landingAirport: '', // 降落机场
      adultPrice: '', // 成人票价
      childrenPrice: '', // 儿童票价
      adultFuelCosts: '', // 成人基建燃油费
      baseCabinName: '', // 舱位类型
      weight: '', // 托运行李额
      discount: '', // 折
      refundExp: '', // 退改手续费
      flightInfoId: '',
      priceInfoId: '',
      showDisplayActive: false,
      DisplayActive: 0,
      CabinName: '',
      adtPrice: '',
      chdPrice: '',
      adtBaggageInfo: [], // 成人行李规定
      chdBaggageInfo: [], // 儿童行李规定
      consign: '',
      portable: '',
      volume: '',
      chdBaggage: '',
      adtRefundFeeList: [],
      chdRefundFeeList: [],
      adtConcessionFeeList: [],
      chdConcessionFeeList: [],
      isChange: '',
      isPay: true, // 是否继续支付
      TakeOppList: [{
        name: '更多',
        id: 9999
      }], // 乘机人列表
      selTakeOppList: [], // 选中的乘机人列表
      form: {
        name: '',
        mobile: ''
      },
      payPrice: 0, // 支付金额
      PayTime: 15 * 60 * 1000, // 支付倒计时
      isShowDetailsText: false, // 是否显示明细文案
      showDetails: false, // 明细
      isShowAdultInfo: false, // 显示成人信息
      isShowChildrenInfo: false, // 显示儿童信息
      adultNum: 0, // 成人购票数量
      ChildrenNum: 0, // 儿童购票数量
      adultTotalPrice: 0, // 成人总机票金额
      adultTotalFuelCosts: 0, // 成人总基建金额
      adultTotalTax: 0, // 成人总燃油金额
      ChildrenTotalPrice: 0, // 儿童总机票金额
      ChildrenTotalFuelCosts: 0, // 儿童总基建金额
      ChildrenTotalTax: 0, // 儿童总燃油金额
      isGoPay: true, // 点击支付
      isShowPopUp: false, // 弹出提示框
      PopUpText: ''// 弹出框文字
    }
  },
  created() {
    this.query = this.$route.query
    this.startAddress = this.query ? this.$route.query.startAddress : this.$route.query.startAddress// 出发地
    this.endAddress = this.query ? this.$route.query.endAddress : this.$route.query.endAddress// 目的地
    this.tripType = this.query ? this.query.tripType : this.query.tripType// 行程状态
    this.TravelText = this.tripType === 'OW' ? '单程' : '往返'
    this.ticketName = this.query ? this.query.ticketName : this.query.ticketName// 航司名称
    this.ticketCode = this.query ? this.query.ticketCode : this.query.ticketCode// 航司码
    this.departureDate = this.query ? this.query.DepartureDate : this.query.DepartureDate// 起飞日期
    this.departureWeek = this.query ? this.query.DepartureWeek : this.query.DepartureWeek// 起飞周天
    this.departureStartTime = this.query ? this.query.DepartureStartTime : this.query.DepartureStartTime// 起飞开始时间
    this.departureEndTime = this.query ? this.query.DepartureEndTime : this.query.DepartureEndTime// 降落时间
    this.departureAirport = this.query ? this.query.DepartureAirport : this.query.DepartureAirport// 起飞机场
    this.landingAirport = this.query ? this.query.LandingAirport : this.query.LandingAirport// 降落机场
    this.adultPrice = this.query ? this.query.AdultPrice : this.query.AdultPrice// 成人票价
    this.childrenPrice = this.query ? this.query.ChildrenPrice : this.query.ChildrenPrice// 儿童票价
    this.adultFuelCosts = this.query ? this.query.AdultFuelCosts : this.query.AdultFuelCosts// 成人燃油费
    this.tax = this.query ? this.query.tax : this.query.tax// 成人基建
    this.baseCabinName = this.query ? this.query.baseCabinName : this.query.baseCabinName// 舱位类型
    this.weight = this.query ? Number(this.query.weight) : Number(this.query.weight)// 托运行李额
    this.discount = this.query ? this.query.discount : this.query.discount// 折
    this.refundExp = this.query ? this.query.refundExp : this.query.refundExp// 退改
    this.flightInfoId = this.query ? this.query.flightInfoId : this.query.flightInfoId//
    this.priceInfoId = this.query ? this.query.priceInfoId : this.query.priceInfoId//
    this.haveMeal = this.query ? this.query.haveMeal : this.query.haveMeal// 是否有餐食
    this.departureTerminal = this.query ? this.query.departureTerminal : this.query.departureTerminal// 出发航站楼
    this.arrivingTerminal = this.query ? this.query.arrivingTerminal : this.query.arrivingTerminal// 目的地航站楼
    this.baseCabin = this.query ? this.query.arrivingTerminal : this.query.arrivingTerminal// 基础舱位
    this.luggage = this.query ? this.query.luggage : this.query.luggage// 行李备注
    this.cabin = this.query ? this.query.cabin : this.query.cabin// 舱位
    // 选中的乘机人列表
    if (JSON.parse(window.localStorage.getItem('selTakeOppList'))) {
      this.selTakeOppList = JSON.parse(window.localStorage.getItem('selTakeOppList'))
    }
    this.form.name = window.localStorage.getItem('nickname')// 乘机人姓名
    this.form.mobile = window.localStorage.getItem('mobile')// 乘机人手机号
    this.getOpportunity()// get乘机人列表
  },
  mounted() {

  },
  methods: {
    // get乘机人
    getOpportunity() {
      getTakeOppList({
        mid: window.localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          this.TakeOppList = this.TakeOppList.concat(res.data.list).reverse()
          for (let i = 0; i < this.TakeOppList.length; i++) {
            this.TakeOppList[i].isSel = false
            for (let j = 0; j < this.selTakeOppList.length; j++) {
              if (this.TakeOppList[i].id !== 9999) {
                if (this.TakeOppList[i].id === this.selTakeOppList[j].id) {
                  this.TakeOppList[i].isSel = true
                  this.AmountPrice(this.TakeOppList[i], 0)
                  this.isShowDetailsText = true
                }
              } else {
                this.TakeOppList[i].isSel = false
              }
            }
          }
        }
      })
    },
    // 展示说明
    showNote() {
      getProductDes({
        flightInfoId: this.flightInfoId,
        tripType: this.tripType,
        priceInfoId: this.priceInfoId
      }).then(res => {
        if (Number(res.code) === 200) {
          this.CabinName = res.data.chd.CabinName// 舱位
          this.adtPrice = res.data.adt.ticketPrice// 成人票价
          this.chdPrice = res.data.chd.ticketPrice// 儿童票价
          this.consign = res.data.adt.luggage.consign// 成人托运行李
          this.portable = res.data.adt.luggage.portable// 成人手提行李
          this.volume = res.data.adt.luggage.volume// 成人手提行李面积
          // 儿童行李标准
          this.chdBaggage = res.data.chd.luggage
          this.adtRefundFeeList = res.data.adt.refundCondition// 成人退票收费list
          this.chdRefundFeeList = res.data.chd.refundCondition// 儿童退票收费标准
          this.adtConcessionFeeList = res.data.adt.rerouteCondition// 成人同舱改期收费规则
          this.chdConcessionFeeList = res.data.chd.rerouteCondition// 儿童同舱改期收费规则
          this.isChange = res.data.chd.transferRule// 改签说明
          this.showDisplayActive = true// 显示产品说明
        }
      })
    },
    // 新增联系人
    IncreaseOpp() {
      this.$router.push({
        path: '/addOpp'
      })
    },
    // 选择乘机人
    selOpp(item) {
      if (item.id === 9999) { // 更多
        this.$router.push({
          path: '/selOpp'
        })
      } else {
        item.isSel = !item.isSel
        if (item.isSel) {
          this.selTakeOppList.push(item)
          this.AmountPrice(item, 0)
          this.isShowDetailsText = true
        } else {
          for (let i = 0; i < this.selTakeOppList.length; i++) {
            if ((this.selTakeOppList)[i].id === item.id) {
              this.selTakeOppList.splice(i, 1)
            }
          }
          this.AmountPrice(item, 1)
          if (this.selTakeOppList.length === 0) {
            this.isShowDetailsText = false
          }
        }
        window.localStorage.setItem('selTakeOppList', JSON.stringify(this.selTakeOppList))
      }
    },
    // 计算金额
    AmountPrice(item, type) {
      if (type === 0) { // 加
        if (item.type === 1) {
          // 支付金额 = 原始支付金额 + 成人机票费用 + 基建费 + 燃油费
          this.payPrice = this.payPrice + Number(this.adultPrice) + Number(this.adultFuelCosts) + Number(this.tax)
        } else {
          // 支付金额 = 原始支付金额 + 儿童支付金额 + 基建费 + 燃油费
          this.payPrice = this.payPrice + Number(this.childrenPrice)
        }
      } else if (type === 1) { // 减
        if (item.type === 1) {
          // 支付金额 = 原始支付金额 - 成人机票费用 - 基建费 - 燃油费
          this.payPrice = this.payPrice - Number(this.adultPrice) - Number(this.adultFuelCosts) - Number(this.tax)
        } else {
          // 支付金额 = 原始支付金额 - 儿童支付金额 - 基建费 - 燃油费
          this.payPrice = this.payPrice - Number(this.childrenPrice)
        }
      }
    },
    // 删除选中信息
    clearUserInfo(item) {
      for (let i = 0; i < this.selTakeOppList.length; i++) {
        if (this.selTakeOppList[i].id === item.id) {
          this.selTakeOppList.splice(i, 1)
          this.AmountPrice(item, 1)
        }
      }
      for (let i = 0; i < this.TakeOppList.length; i++) {
        if (this.TakeOppList[i].id === item.id) {
          this.TakeOppList[i].isSel = false
        }
      }
      if (this.selTakeOppList.length === 0) {
        this.isShowDetailsText = false
      }
      window.localStorage.setItem('selTakeOppList', JSON.stringify(this.selTakeOppList))
    },
    // 查看明细
    lookDetail() {
      for (let i = 0; i < this.selTakeOppList.length; i++) {
        if (this.selTakeOppList[i].type === 1) {
          this.isShowAdultInfo = true
          this.adultNum++
        }
        if (this.selTakeOppList[i].type === 2) {
          this.isShowChildrenInfo = true
          this.ChildrenNum++
        }
        // 成人机票价格
        this.adultTotalPrice = this.adultPrice * this.adultNum
        // 成人基建费
        this.adultTotalTax = this.adultFuelCosts * this.adultNum
        // 成人燃油费
        this.adultTotalFuelCosts = this.tax * this.adultNum
        this.ChildrenTotalPrice = this.childrenPrice * this.ChildrenNum// 儿童机票价格
        this.ChildrenTotalFuelCosts = 0// 儿童基建费
        this.ChildrenTotalTax = 0// 儿童燃油费
      }
      this.showDetails = true
    },
    // 单程生成订单
    getOder() {
      if (this.selTakeOppList.length === 0) {
        Toast('请选择乘客')
      } else {
        this.isPay = false// 显示支付乘客信息
        this.PopUpText = '正在生成订单中。。。'
        this.isShowPopUp = true// 弹出层
        if (this.isGoPay === true) {
          this.isGoPay = false// 支付按钮禁止状态
          for (let i = 0; i < this.selTakeOppList.length; i++) {
            this.selTakeOppList[i].baseCabin = this.baseCabin
            this.selTakeOppList[i].luggage = this.luggage
            this.selTakeOppList[i].cabin = this.cabin
          }
          getTicketOder({
            flightInfoId: this.flightInfoId,
            priceInfoId: this.priceInfoId,
            memberId: window.localStorage.getItem('uid'),
            mobile: window.localStorage.getItem('mobile'), // 用户手机号
            contactName: this.form.name, // 联系人姓名
            contactPhone: this.form.mobile, // 联系人手机号
            depCity: this.startAddress, // 出发城市名称
            arrCity: this.endAddress, // 到达城市名称
            depAirportName: this.departureAirport, // 出发机场名称
            arrAirportName: this.landingAirport, // 到达机场名称
            departureTerminal: this.departureTerminal, // 出发航站楼
            arrivingTerminal: this.arrivingTerminal, // 到达航站楼
            hasMeal: this.haveMeal, // 是否有餐食
            passengers: this.selTakeOppList// 乘机人信息
          }).then(res => {
            this.isGoPay = true// 支付按钮恢复状态
            if (Number(res.code) === 200) {
              this.order_no = res.data.order_no
              this.goPay(res.data.order_no)
            }
          })
        }
      }
    },
    // 支付
    goPay(orderNo) {
      payTicketOder({
        orderNo: orderNo,
        payType: 1, // 1微信2支付宝
        paySource: 6
      }).then(res => {
        if (Number(res.code) === 200) {
          this.isShowPopUp = false// 关闭弹窗提示
          const params = res.pay.getwayBody
          this.wxPay(params)
        } else {
          this.isGoPay = true// 支付按钮恢复状态
        }
      })
    },
    /**
     * 调用微信支付
     * 2021-06-04 10:22:15
     * @author SnowRock
     * @param params
     */
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
          }
        }
        that.isDisabledSubmitBtn = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;

  .titleWrap {
    width: 100%;
    height: 42px;
    background-color: #fff;
    text-align: center;
    line-height: 42px;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;

    .titleText {
      display: flex;
      line-height: 42px;

      p {
        font-size: 17px;
        color: #333333;
        font-weight: 600;
      }

      img {
        width: 16px;
        height: 5px;
        margin: 17px 8px;
      }

    }
  }

  .tickedTimeDetail {
    padding: 31px 23px 1px;
    background-color: #fff;
    margin-top: 43px;

    .AviationInfo {
      display: flex;

      img {
        width: 20px;
        height: 23px;
        margin-left: 28px;
      }

      p {
        line-height: 17px;
        font-size: 13px;
        color: #333333;
      }

      p:nth-child(1) {
        width: 32px;
        height: 17px;
        text-align: center;
        background-color: #E33F44;
        color: #ffffff;
        border-radius: 3px;

        span {
          display: inline-block;
          transform: scale(0.8);
        }
      }

      p:nth-child(2) {
        margin-left: 7px;
      }

      p:nth-child(3) {
        margin-left: 10px;
      }

      p:nth-child(4) {
        margin-left: 10px;
      }
    }

    .TimeInfo {
      display: flex;
      margin-top: 20px;
      margin-bottom: 30px;

      .stareTimeInfo, .endTimeInfo {
        color: #333333;
        flex: 1;

        p:nth-child(1) {
          font-size: 29px;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 12px;
        }
      }

      .TimeImg {
        flex: 1;

        img {
          width: 52px;
          height: 6px;
          margin: 14px 28px;
        }
      }
    }
  }

  .priceTipsInfo {
    padding: 17px;
    background-color: #fff;

    .priceTipsInfoContent {
      text-align: left;

      .tickedListLeftContent1 {
        margin-top: 9px;
      }

      .tickedListLeftContent2 {
        margin-top: 17px;
      }

      .tickedListLeftContent {
        /*margin-left: 5px;*/
        display: flex;

        p {
          color: #333333;

          span {
            display: inline-block;
            transform: scale(0.9);
          }
        }

        img {
          width: 5px;
          height: 9px;
          margin-top: 5px;
        }
      }

      p {
        line-height: 20px;
      }

      p:nth-child(1), p:nth-child(2) {
        font-size: 14px;
        color: #333333;
      }

      p:nth-child(4) {
        font-size: 12px;
        color: #E33F44;
      }
    }
  }

  /*乘机人*/

  .TakeOppListWrap {
    padding: 18px 13px;
    margin-top: 7px;
    background-color: #fff;

    .TakeOppListTitle {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }

    .TakeOppList {
      margin-top: 10px;
      margin-bottom: 13px;

      .TakeOppListInfos {
        display: flex;
        flex-wrap: wrap;

        .TakeOppListInfo, .NewDataOpp {
          width: 112px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          font-size: 12px;
          border-radius: 3px;
          margin-bottom: 7px;
          border: 1px solid #999999;
        }

        .TakeOppListInfo {
          color: #999999;
        }

        .TakeOppListInfo1 {
          margin-left: 6px;
          margin-right: 6px;
        }

        .TakeOppListInfo2 {
          border: 1px solid #E33F44;
          color: #E33F44;
        }

      }

      .NewDataOpp {
        color: #E33F44;
      }
    }

    .TakeOppTips {
      font-size: 12px;
      color: #666666;
    }

    /*选中乘机人*/

    .selOpportunity {
      .selOpportunityList {
        display: flex;
        padding: 17px 0;

        .Forks {
          width: 17px;
          height: 17px;
          font-size: 20px;
          border: 1px solid #E33F44;
          color: #E33F44;
          border-radius: 50%;
          text-align: center;
          line-height: 15px;
          margin-top: 10px;
        }

        .userInfo {
          color: #333333;
          margin-left: 18px;

          p:nth-child(1) {
            font-size: 17px;
            font-weight: 600;
          }

          p:nth-child(2) {
            font-size: 14px;
            margin-top: 8px;
          }
        }
      }
    }

  }

  .selTakeOppListWrap {
    margin-top: 7px;
    background-color: #fff;
    padding: 15px 13px;

    p:nth-child(1) {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }

    .infoContent {
      margin-top: 7px;

      .infoFill {
        display: flex;
        height: 54px;
        line-height: 54px;

        .infoFillTitle {
          width: 30%;
          font-size: 14px;
          color: #666666;
        }

        .infoFillInp {
          width: 60%;
          background-color: #fff;
        }

        .iconStyle {
          margin-left: auto;
          line-height: 54px;
        }
      }
    }
  }

  .voucherWrap {
    display: flex;
    font-size: 12px;
    color: #E33F44;
    padding: 15px;
    line-height: 15px;
    background-color: #f8f8f8;
  }

  /*底部支付*/

  .btnPay {
    width: 100%;
    height: 75px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 18px 14px;

    .priceWrap {
      p:nth-child(1) {
        font-size: 18px;
        color: #242424;
        font-weight: 600;

        span {
          color: #E33F44;
        }
      }

      p:nth-child(2) {
        margin-top: 9px;
        font-size: 13px;
        color: #999999;
      }
    }

    .payWrap {
      margin-left: auto;
      display: flex;

      .DetailsText {
        font-size: 13px;
        color: #999999;
        line-height: 45px;
      }

      .goPay {
        margin-left: 20px;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 17px;
        color: #ffffff;
        background-color: #E33F44;
        border-radius: 20px;

        .payTime {
          font-size: 12px;
        }
      }

      .ProhibitionGoPay {
        background-color: #999999;
      }
    }
  }

  /*展示说明*/

  .DisplayActiveWrap {
    padding: 16px 20px;

    .DisplayActiveTab {
      padding-top: 16px;
    }

    .TicketTitle {
      display: flex;

      img {
        width: 13px;
        height: 13px;
      }

      p {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        margin-left: 10px;
      }
    }

    .TicketContent {
      width: 100%;
      margin-top: 11px;
      border-radius: 3px;
      border: 1px solid #E8E8E8;
      text-align: left;

      .TicketContentTitle {
        width: 100%;
        border-radius: 3px 3px 0 0;
        background-color: #f8f8f8;
        font-size: 13px;
        color: #333333;
        padding: 13px 14px;
        font-weight: 500;
      }

      .TicketContentInfo {
        width: 100%;
        display: flex;

        p {
          font-size: 13px;
          padding-left: 14px;
          height: 40px;
          line-height: 40px;
        }

        p:nth-child(1) {
          width: 53px;
          font-weight: 600;
          color: #333333;
        }

        p:nth-child(3), p:nth-child(5) {
          color: #666666;
        }

        p:nth-child(3) {
          width: 155px;
        }

        p:nth-child(5) {
          width: 104px;
        }
      }

      /*行李info*/

      .TicketBaggageContentInfo {
        /*display: flex;*/

        .chdBaggageInfo, .adtBaggageInfo {
          display: flex;

          .chdBaggageInfoT1 {
            width: 149px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }

          .chdBaggageInfoT2 {
            width: 117px;
            line-height: 20px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }
        }

        .chdBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1 {
            line-height: 40px;
          }
        }

        .adtBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1, .chdBaggageInfoT2 {
            line-height: 30px;
          }
        }
      }
    }

    .TicketRemarks {
      width: 100%;
      margin-top: 11px;
      text-align: left;
      line-height: 15px;

      p {
        font-size: 12px;
        color: #666666;
      }
    }
  }

  /*    购买明细*/

  .infoDetailWrap {
    padding: 23px 17px;
    text-align: left;

    .AdultInfoDetail, .ChildrenInfoDetail {
      .AdultInfoPrice, .AdultInfoTicketPrice, .AdultInfoFuelCostsPrice, .AdultInfoTaxPrice {
        display: flex;
        color: #333333;

        p:nth-child(1) {
          margin-bottom: 18px;
        }

        p:nth-child(2) {
          margin-left: auto;
        }
      }

      .AdultInfoTicketPrice, .AdultInfoFuelCostsPrice, .AdultInfoTaxPrice {
        font-size: 12px;

        p:nth-child(2), p:nth-child(3), p:nth-child(4) {
          margin-bottom: 12px;
        }
      }

      .AdultInfoPrice {
        p {
          font-size: 14px;
        }

        p:nth-child(3) {
          margin-left: auto;
        }
      }
    }

    .payTips {
      margin-top: 14px;

      p {
        font-size: 12px;
        color: #E33F44;
      }
    }
  }
}

/deep/ .van-popup--center.van-popup--round {
  padding: 20px;
}
</style>
